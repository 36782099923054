@import "/src/styles/mixin";

.wrap {
  min-width: 288px;
  height: 250px;
  margin-bottom: 24px;
  padding-right: 8px;
  overflow-x: hidden;
}

.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.subtitle {
  @include font16;
  margin-bottom: 24px;
}

.input {
  padding: 16px 20px 8px;
  border: none;
  width: 400px;
  &Wrap {
    margin-bottom: 24px;
  }
}

.button {
  padding: 14px 26px;
  width: 100%;
}

.checkbox {
  margin-bottom: 24px;
}

.radioButton {
  @include font14;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid var(--grey-lines-color);
}
